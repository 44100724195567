var render = function () {
  var _vm$resolvedUser$acti, _vm$resolvedUser$acti2, _vm$defaultAgencyBonu, _vm$defaultAgencyBonu2, _vm$defaultAgencyBonu3, _vm$defaultAgencyBonu4, _vm$defaultAgencyBonu5, _vm$resolvedUser$acti3, _vm$resolvedUser$acti4, _vm$resolvedUser$acti5, _vm$resolvedUser$acti6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "userLine",
    class: {
      small: _vm.small,
      onDark: _vm.onDark
    }
  }, [_c('UserProfilePhoto', {
    attrs: {
      "id": "userPhoto",
      "user": _vm.resolvedUser
    }
  }), _c('div', {
    class: {
      dynamicWidth: _vm.dynamicWidth
    },
    attrs: {
      "id": "userInfo"
    }
  }, [!_vm.resolvedUser ? _c('LoadingSpinner', {
    attrs: {
      "defaultDimensions": false
    }
  }) : [_vm.showAdminTooltip ? [_vm.defaultAgencyBonusParams || (_vm$resolvedUser$acti = _vm.resolvedUser.activeAgencyIdListAsAdmin) !== null && _vm$resolvedUser$acti !== void 0 && _vm$resolvedUser$acti.length || (_vm$resolvedUser$acti2 = _vm.resolvedUser.activeAgencyIdListAsAgent) !== null && _vm$resolvedUser$acti2 !== void 0 && _vm$resolvedUser$acti2.length ? _c('div', {
    staticClass: "indicator",
    staticStyle: {
      "border-color": "#b2d5ff"
    }
  }, [_c('AgentIcon', {
    attrs: {
      "color": "#b2d5ff"
    }
  })], 1) : _vm._e(), _vm.inviteUser ? _c('div', {
    staticClass: "indicator",
    staticStyle: {
      "border-color": "#d9b2e7"
    }
  }, [_c('InvitesIcon', {
    attrs: {
      "color": "#d9b2e7"
    }
  })], 1) : _vm._e(), _vm.banFlag ? _c('div', {
    staticClass: "indicator",
    style: {
      borderColor: _vm.banFlag.color
    }
  }, [_c('WarningIcon', {
    attrs: {
      "color": _vm.banFlag.color
    }
  })], 1) : _vm.communityVip ? _c('div', {
    staticClass: "indicator",
    staticStyle: {
      "border-color": "#b2d8bb"
    }
  }, [_c('VipShieldIcon', {
    attrs: {
      "color": "#b2d8bb"
    }
  })], 1) : _vm.internalNotes ? _c('div', {
    staticClass: "indicator",
    staticStyle: {
      "border-color": "#CBCCCF"
    }
  }, [_c('NotesIcon', {
    attrs: {
      "color": "#CBCCCF"
    }
  })], 1) : _vm._e()] : _vm._e(), _c('div', {
    attrs: {
      "id": "userInfoPlaceholder"
    }
  }, [_c('div', {
    class: {
      absolute: !_vm.dynamicWidth,
      centerVertical: !_vm.dynamicWidth
    },
    attrs: {
      "id": "userInfoInner"
    }
  }, [_vm.resolvedUser.username || _vm.resolvedUser.fullName || _vm.resolvedSecondLineText ? [_vm.resolvedUser.fullName ? _c('div', {
    attrs: {
      "id": "userInfoFirstLine"
    }
  }, [_vm._v(_vm._s(_vm.resolvedUser.fullName)), _vm.resolvedUser.username ? _c('span', [_vm._v(" (@" + _vm._s(_vm.resolvedUser.username) + ")")]) : _vm._e()]) : _vm.resolvedUser.username ? _c('div', {
    attrs: {
      "id": "userInfoFirstLine"
    }
  }, [_vm._v("@" + _vm._s(_vm.resolvedUser.username))]) : _vm._e(), _vm.resolvedSecondLineText ? _c('div', {
    class: {
      bold: !_vm.secondLineText
    },
    attrs: {
      "id": "userInfoSecondLine"
    }
  }, [_vm._v(_vm._s(_vm.resolvedSecondLineText))]) : _vm._e()] : _vm._t("noUserInfo", [_c('div', {
    attrs: {
      "id": "userInfoFirstLine"
    }
  }, [_vm._v("[Unnamed User]")])])], 2)]), _vm.showAdminTooltip ? _c('Tooltip', {
    staticClass: "fillParent",
    staticStyle: {
      "cursor": "auto"
    },
    attrs: {
      "showOnHover": "",
      "direction": _vm.adminTooltipDirection,
      "horizontalOffset": "75px",
      "triangleHorizontalOffset": "-75px",
      "maxWidth": "300px",
      "backgroundColor": _vm.adminTooltipBackgroundColor
    },
    nativeOn: {
      "mouseover": function mouseover($event) {
        return _vm.downloadMoreInfo($event);
      },
      "click": function click($event) {
        $event.stopPropagation();
        return function () {}($event);
      }
    }
  }, [_vm.defaultAgencyBonusParams ? _c('div', {
    staticClass: "tooltipInfoBox",
    staticStyle: {
      "border-color": "#b2d5ff"
    }
  }, [_c('AgentIcon', {
    attrs: {
      "color": "#b2d5ff"
    }
  }), _c('h4', {
    staticStyle: {
      "color": "#b2d5ff"
    }
  }, [_vm._v(" Primary Agent: "), _c('router-link', {
    attrs: {
      "target": "_blank",
      "to": {
        name: 'consoleUsers',
        query: {
          search: _vm.defaultAgencyBonusParams.agentUserId
        }
      }
    }
  }, [_vm._v(_vm._s(((_vm$defaultAgencyBonu = _vm.defaultAgencyBonusParams.agentUser) === null || _vm$defaultAgencyBonu === void 0 ? void 0 : _vm$defaultAgencyBonu.fullName) || '[unnamed agent]') + _vm._s(((_vm$defaultAgencyBonu2 = _vm.defaultAgencyBonusParams.agentUser) === null || _vm$defaultAgencyBonu2 === void 0 ? void 0 : _vm$defaultAgencyBonu2.username) && " (@".concat((_vm$defaultAgencyBonu3 = _vm.defaultAgencyBonusParams.agentUser) === null || _vm$defaultAgencyBonu3 === void 0 ? void 0 : _vm$defaultAgencyBonu3.username, ")")))]), _c('br'), _vm._v(" Primary Agency: "), _c('router-link', {
    attrs: {
      "to": {
        name: 'consoleAgency',
        params: {
          agencyId: (_vm$defaultAgencyBonu4 = _vm.defaultAgencyBonusParams) === null || _vm$defaultAgencyBonu4 === void 0 ? void 0 : _vm$defaultAgencyBonu4.agencyId
        }
      }
    }
  }, [_vm._v("agency " + _vm._s((_vm$defaultAgencyBonu5 = _vm.defaultAgencyBonusParams) === null || _vm$defaultAgencyBonu5 === void 0 ? void 0 : _vm$defaultAgencyBonu5.agencyId))])], 1)], 1) : _vm._e(), (_vm$resolvedUser$acti3 = _vm.resolvedUser.activeAgencyIdListAsAdmin) !== null && _vm$resolvedUser$acti3 !== void 0 && _vm$resolvedUser$acti3.length || (_vm$resolvedUser$acti4 = _vm.resolvedUser.activeAgencyIdListAsAgent) !== null && _vm$resolvedUser$acti4 !== void 0 && _vm$resolvedUser$acti4.length ? _c('div', {
    staticClass: "tooltipInfoBox",
    staticStyle: {
      "border-color": "#b2d5ff"
    }
  }, [_c('AgentIcon', {
    attrs: {
      "color": "#b2d5ff"
    }
  }), _c('h4', {
    staticStyle: {
      "color": "#b2d5ff",
      "display": "flex",
      "flex-direction": "column",
      "align-items": "flex-start"
    }
  }, [(_vm$resolvedUser$acti5 = _vm.resolvedUser.activeAgencyIdListAsAdmin) !== null && _vm$resolvedUser$acti5 !== void 0 && _vm$resolvedUser$acti5.length ? _c('span', [_vm._v("Admin for "), _vm._l(_vm.resolvedUser.activeAgencyIdListAsAdmin, function (agencyId, i) {
    return _c('span', {
      key: agencyId
    }, [_vm._v(_vm._s(i > 0 ? ', ' : '')), _c('router-link', {
      attrs: {
        "to": {
          name: 'consoleAgency',
          params: {
            agencyId: agencyId
          }
        }
      }
    }, [_vm._v("agency " + _vm._s(agencyId))])], 1);
  })], 2) : _vm._e(), (_vm$resolvedUser$acti6 = _vm.resolvedUser.activeAgencyIdListAsAgent) !== null && _vm$resolvedUser$acti6 !== void 0 && _vm$resolvedUser$acti6.length ? _c('span', [_vm._v("Agent for "), _vm._l(_vm.resolvedUser.activeAgencyIdListAsAgent, function (agencyId, i) {
    return _c('span', {
      key: agencyId
    }, [_vm._v(_vm._s(i > 0 ? ', ' : '')), _c('router-link', {
      attrs: {
        "to": {
          name: 'consoleAgency',
          params: {
            agencyId: agencyId
          }
        }
      }
    }, [_vm._v("agency " + _vm._s(agencyId))])], 1);
  })], 2) : _vm._e()])], 1) : _vm._e(), _vm.inviteUser ? _c('div', {
    staticClass: "tooltipInfoBox",
    staticStyle: {
      "border-color": "#d9b2e7"
    }
  }, [_c('InvitesIcon', {
    attrs: {
      "color": "#d9b2e7"
    }
  }), _c('h4', {
    staticStyle: {
      "color": "#d9b2e7"
    }
  }, [_vm._v("Invited by "), _c('router-link', {
    attrs: {
      "target": "_blank",
      "to": {
        name: 'consoleUsers',
        query: {
          search: _vm.inviteUser.id
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.inviteUser.label))])], 1)], 1) : _vm._e(), _vm.banFlag ? _c('div', {
    staticClass: "tooltipInfoBox",
    style: {
      borderColor: _vm.banFlag.color
    }
  }, [_c('WarningIcon', {
    attrs: {
      "color": _vm.banFlag.color
    }
  }), _c('h4', {
    style: {
      color: _vm.banFlag.color
    }
  }, [_vm._v(_vm._s(_vm.banFlag.text))])], 1) : _vm.communityVip ? _c('div', {
    staticClass: "tooltipInfoBox",
    staticStyle: {
      "border-color": "#b2d8bb"
    }
  }, [_c('VipShieldIcon', {
    attrs: {
      "color": "#b2d8bb"
    }
  }), _c('h4', {
    staticStyle: {
      "color": "#b2d8bb"
    }
  }, [_vm._v("VIP")])], 1) : _vm._e(), _vm.internalNotes ? _c('div', {
    staticClass: "tooltipInfoBox",
    staticStyle: {
      "border-color": "#CBCCCF"
    },
    attrs: {
      "id": "notesBox"
    }
  }, [_c('div', {
    staticClass: "inlineHeader"
  }, [_c('NotesIcon', {
    attrs: {
      "color": "#CBCCCF"
    }
  }), _c('span', [_vm._v("Notes")])], 1), _c('MultilineText', [_vm._v(_vm._s(_vm.internalNotes))])], 1) : _vm._e(), _vm._t("adminTooltip"), _vm.$route.name !== 'consoleUsers' || _vm.$route.search !== _vm.resolvedUser.id ? [_vm.inConsole ? _c('router-link', {
    attrs: {
      "target": "_blank",
      "to": {
        name: 'consoleUsers',
        query: {
          search: _vm.resolvedUser.id
        }
      }
    }
  }, [_c('PillButton', {
    attrs: {
      "small": ""
    }
  }, [_c('OpenInNewWindowIcon', {
    staticClass: "newWindowIcon"
  }), _vm._v(" View Alone in Users Console")], 1)], 1) : _c('a', {
    attrs: {
      "href": _vm.makeReachUrl("/users?search=".concat(_vm.resolvedUser.id), {
        isConsole: true
      }),
      "target": "_blank"
    }
  }, [_c('PillButton', {
    attrs: {
      "small": ""
    }
  }, [_c('OpenInNewWindowIcon', {
    staticClass: "newWindowIcon"
  }), _vm._v("View Alone in Users Console")], 1)], 1)] : _vm._e(), _vm.$route.params.brandCampaignId && _vm.inConsole ? [_c('router-link', {
    attrs: {
      "target": "_blank",
      "to": {
        name: 'consoleDms',
        query: {
          dmConversationId: [_vm.resolvedUser.id, _vm.$route.params.brandCampaignId].join('-'),
          userIds: _vm.resolvedUser.id
        }
      }
    }
  }, [_c('PillButton', {
    attrs: {
      "small": ""
    }
  }, [_c('OpenInNewWindowIcon', {
    staticClass: "newWindowIcon"
  }), _vm._v(" View Campaign-User DM")], 1)], 1)] : _vm._e(), _c('a', {
    attrs: {
      "href": _vm.makeFirestoreUrl(_vm.USER_DOC_PATH(_vm.resolvedUser.id)),
      "target": "_blank"
    }
  }, [_c('PillButton', {
    attrs: {
      "small": "",
      "gradientType": "secondary"
    }
  }, [_c('OpenInNewWindowIcon', {
    staticClass: "newWindowIcon"
  }), _vm._v("View Firestore User Doc")], 1)], 1), _vm.$route.params.brandCampaignId ? [_c('a', {
    attrs: {
      "href": _vm.makeFirestoreUrl(_vm.BRAND_CAMPAIGN_USER_RELATIONSHIP_DOC_PATH([_vm.resolvedUser.id, _vm.$route.params.brandCampaignId].join('-'))),
      "target": "_blank"
    }
  }, [_c('PillButton', {
    attrs: {
      "small": "",
      "gradientType": "secondary"
    }
  }, [_c('OpenInNewWindowIcon', {
    staticClass: "newWindowIcon"
  }), _vm._v("View Firestore Relationship Doc")], 1)], 1)] : _vm._e()], 2) : _vm._e()]], 2), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }