
// SHARED REPO
import {
  initShared,
  isDev,
} from '@happstv/shared/util/utils'
// eslint-disable-next-line import/order
import logger from '@/logger'
/* eslint-disable import/first */
initShared(firebase.firestore, logger)

// GLOBAL LIBRARIES
import firebase from 'firebase/app'
import { datadogRum } from '@datadog/browser-rum'
import moment from 'moment'

// TOP-LEVEL FILES
import Vue from '@/setupGlobalComponents'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

// GLOBAL LIBRARY SETUP
datadogRum.init({
  applicationId: 'c599782f-d39b-4ec1-a944-72cfc814f7e1',
  clientToken: 'pub6f0fd241834c284124a728757d3b3ab2',
  site: 'datadoghq.com',
  service: 'starlight',
  env: isDev ? 'dev' : 'prod',
  // The __COMMIT_HASH__ comes from a global defined in vue.config.js
  version: __COMMIT_HASH__,
  sampleRate: 100,
  trackInteractions: true,
  useSecureSessionCookie: true,
  defaultPrivacyLevel: 'mask-user-input',
})
datadogRum.startSessionReplayRecording()

// SITE-SPECIFIC VUE SETUP
Vue.config.productionTip = false

router.$store = store
store.commit('SET_ROUTER', router)

moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s:  '1s',
    ss: '%ss',
    m:  '1m',
    mm: '%dm',
    h:  '1h',
    hh: '%dh',
    d:  '1d',
    dd: '%dd',
    M:  '1M',
    MM: '%dM',
    y:  '1Y',
    yy: '%dY',
  },
})

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    this.$store.dispatch('INIT_DEVICE_ID') // TODO MOVE THIS TO THE STORE
  },
}).$mount('#app')
